@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif !important;
}
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

input,
select,
textarea {
  background-color: transparent;
  outline: none;
}
button {
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: 0;
}
body {
  min-height: 100vh;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
}
/* Kiểu thanh cuộn ngang */
*::-webkit-scrollbar {
  width: 8px;
  border-radius: 100rem;
  
}

/* Kiểu thanh cuộn dọc */
*::-webkit-scrollbar-track {
  background-color: #e2e9ff;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #008c48;
  border-radius: 10px;
}

@-webkit-keyframes spincube {
  from,to  { -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); }
  16%      { -webkit-transform: rotateY(-90deg);                           }
  33%      { -webkit-transform: rotateY(-90deg) rotateZ(90deg);            }
  50%      { -webkit-transform: rotateY(-180deg) rotateZ(90deg);           }
  66%      { -webkit-transform: rotateY(-270deg) rotateX(90deg);           }
  83%      { -webkit-transform: rotateX(90deg);                            }
}

@keyframes spincube {
  from,to {
    -moz-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    -ms-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    -moz-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  33% {
    -moz-transform: rotateY(-90deg) rotateZ(90deg);
    -ms-transform: rotateY(-90deg) rotateZ(90deg);
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  50% {
    -moz-transform: rotateY(-180deg) rotateZ(90deg);
    -ms-transform: rotateY(-180deg) rotateZ(90deg);
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    -moz-transform: rotateY(-270deg) rotateX(90deg);
    -ms-transform: rotateY(-270deg) rotateX(90deg);
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    -moz-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    transform: rotateX(90deg);
  }
}

.cubespinner {
  -webkit-animation-name: spincube;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 12s;

  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;

  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;

  -webkit-transform-origin: 100px 100px 0;
  -moz-transform-origin: 100px 100px 0;
  -ms-transform-origin: 100px 100px 0;
  transform-origin: 100px 100px 0;
}

.cubespinner div {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px solid #989898;
  -webkit-box-shadow: 0 0 16px 1px #e9ffe4;
-moz-box-shadow: 0 0 16px 1px #e9ffe4;
box-shadow: 0 0 16px 1px #e9ffe4;
  text-align: center;
}

.cubespinner .face1 {
  -webkit-transform: translateZ(100px);
  -moz-transform: translateZ(100px);
  -ms-transform: translateZ(100px);
  transform: translateZ(100px);
}
.cubespinner .face2 {
  -webkit-transform: rotateY(90deg) translateZ(100px);
  -moz-transform: rotateY(90deg) translateZ(100px);
  -ms-transform: rotateY(90deg) translateZ(100px);
  transform: rotateY(90deg) translateZ(100px);
}
.cubespinner .face3 {
  -webkit-transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
  -moz-transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
  -ms-transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
  transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
}
.cubespinner .face4 {
  -webkit-transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
  -moz-transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
  -ms-transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
  transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
}
.cubespinner .face5 {
  -webkit-transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
  -moz-transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
  -ms-transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
  transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
}
.cubespinner .face6 {
  -webkit-transform: rotateX(-90deg) translateZ(100px);
  -moz-transform: rotateX(-90deg) translateZ(100px);
  -ms-transform: rotateX(-90deg) translateZ(100px);
  transform: rotateX(-90deg) translateZ(100px);
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px dotted #00ff85;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

.loaderBtn {
  width: 30px;
  height: 30px;
  border: 5px dotted #181818;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

